var offset = 832;
if ($(window).width() <= 768) {
    offset = 412;
}

$( "#news-previous" ).click(function() {
    var position = $( ".news-list" ).scrollLeft();
    $( ".news-list" ).scrollLeft(position - offset);
    
});


$( "#news-next" ).click(function() {
    var position = $( ".news-list" ).scrollLeft();
    $( ".news-list" ).scrollLeft(position + offset);

    // $('.news-list').animate({
    //     scrollLeft: position + 800
    // });
});